<template>
  <Tooltip :text="iconData.name" class="delivery-route-icon" :style="style">
    <component :is="iconData.icon" />
  </Tooltip>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { hexToRGBA } from '@/helpers/Utils'
import { GREY } from '@/consts/Colors'
import Tooltip from '@/views/Elements/Tooltip.vue'
import EventStatuses from '@/consts/EventStatuses'
import InfoReceivedIcon from '@/views/Elements/Icons/TrackingStatus/InfoReceivedIcon.vue'

export default defineComponent({
  name: 'EventIcon',
  components: { Tooltip },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const iconData = computed(() => {
      return EventStatuses[props.status] || { name: '', icon: InfoReceivedIcon, color: GREY }
    })

    const style = computed(() => {
      const { color } = iconData.value
      return {
        background: hexToRGBA(color ? color : GREY, 0.24),
        color: color ? color : GREY,
      }
    })

    return {
      iconData,
      style,
    }
  },
})
</script>
