<template>
  <div>
    <h3>Delivery route</h3>
    <ul class="delivery-route mt-3">
      <li
        v-for="(event, index) in eventsForDisplay"
        :key="`event-id-${event.id}`"
        :class="{ 'last-child': index === 2 && !showEventsMore }"
      >
        <EventRouteBlock :event="event" />
      </li>
    </ul>
    <b-button
      v-if="events.length > limit"
      variant="link"
      class="p-0 expand-list"
      :class="{ active: showEventsMore }"
      @click="toggleShowMoreEvent"
    >
      {{ moreEventsBtnText }}
      <ExpandIcon />
    </b-button>
    <hr class="tracking-divider" />
  </div>
</template>

<script lang="ts">
import type { TrackingEvent } from '@/types/Models/Shipment'
import { defineComponent, ref, computed, Ref, ComputedRef } from 'vue'
import ExpandIcon from '@/views/Elements/Icons/ExpandIcon.vue'
import EventRouteBlock from '@/views/Components/EventRouteBlock.vue'

export default defineComponent({
  name: 'TrackingEvents',
  components: {
    EventRouteBlock,
    ExpandIcon,
  },
  props: {
    events: {
      type: Array as TrackingEvent[],
      required: true,
    },
  },
  setup(props) {
    const showEventsMore: Ref<boolean> = ref(false)
    const limit = 3

    const moreEventsBtnText: ComputedRef<string> = computed(() => {
      return showEventsMore.value ? 'View less' : 'View more'
    })

    const eventsForDisplay: ComputedRef<TrackingEvent[]> = computed(() => {
      return showEventsMore.value ? props.events : props.events.slice(0, limit)
    })

    const toggleShowMoreEvent = () => {
      showEventsMore.value = !showEventsMore.value
    }

    return {
      limit,
      showEventsMore,
      moreEventsBtnText,
      eventsForDisplay,
      toggleShowMoreEvent,
    }
  },
})
</script>
