<template>
  <div>
    <h2 class="mb-3">Parcel information</h2>
    <ul class="parcel-info d-flex">
      <li>
        <span class="d-block text-black-50">Weight</span>
        {{ itemData.weight }} g
      </li>
      <li>
        <span class="d-block text-black-50">Height</span>
        {{ itemData.height }} cm
      </li>
      <li>
        <span class="d-block text-black-50">Length</span>
        {{ itemData.length }} cm
      </li>
      <li>
        <span class="d-block text-black-50">Width</span>
        {{ itemData.width }} cm
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import type { TrackingData } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'DimensionsInfo',
  props: {
    itemData: {
      type: Object as PropType<TrackingData>,
      required: true,
    },
  },
})
</script>
