<template>
  <div>
    <h2>Track your shipments</h2>

    <div class="tracking-form d-flex align-items-center">
      <b-form-input v-model="trackingNumber" placeholder="Shipment ID" />

      <b-button class="btn-green ml-3 px-0" :disabled="trackingNumber.length === 0" @click="tracking">
        <SearchIcon />
      </b-button>
    </div>

    <p v-if="savedTracking.length > 0">Previous searches</p>
    <ul class="previous-searches">
      <li v-for="number in savedTracking" :key="number" class="mb-2">
        <b-button class="searches-button mt-2 mt-md-0" @click="trackingPrevious(number)">
          {{ number }}
        </b-button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { DETAILS_PAGE } from '@/consts/Router'
import SearchIcon from '@/views/Elements/Icons/SearchIcon.vue'

export default defineComponent({
  name: 'Search',
  components: {
    SearchIcon,
  },
  setup() {
    const trackingNumber = ref<string>('')
    const savedTracking = ref<string[]>([])
    const router = useRouter()

    onMounted(() => {
      const saved = localStorage.getItem('savedTracking')
      if (saved) {
        savedTracking.value = JSON.parse(saved)
      }
    })

    const tracking = () => {
      savedTracking.value.push(trackingNumber.value)
      localStorage.setItem('savedTracking', JSON.stringify([...new Set(savedTracking.value)]))
      redirect()
    }

    const trackingPrevious = (number: string) => {
      trackingNumber.value = number
      redirect()
    }

    const redirect = () => {
      const params = {
        name: DETAILS_PAGE,
        params: { id: trackingNumber.value },
      }
      router.push(params)
    }

    return {
      trackingNumber,
      savedTracking,
      tracking,
      trackingPrevious,
    }
  },
})
</script>

<style lang="scss" scoped></style>
