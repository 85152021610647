import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { DETAILS_PAGE, PRIVACY, SEARCH_PAGE, TERMS } from '@/consts/Router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/terms-of-use/',
    component: () => import('@/views/Pages/TermsOfUse.vue'),
    name: TERMS,
    meta: {
      title: '21grams | Terms of use',
    },
  },
  {
    path: '/privacy-policy/',
    component: () => import('@/views/Pages/PrivacyPolicy.vue'),
    name: PRIVACY,
    meta: {
      title: '21grams | Privacy Policy',
    },
  },
  {
    path: '/',
    component: () => import('@/views/Pages/Index.vue'),
    meta: {
      title: '21grams | Parcel Tracking',
    },
    children: [
      {
        path: '',
        component: () => import('@/views/Pages/Search.vue'),
        name: SEARCH_PAGE,
        meta: {
          title: '21grams | Parcel Tracking',
        },
      },
      {
        path: 'tracking/:id',
        component: () => import('@/views/Pages/TrackingNumberInfo.vue'),
        name: DETAILS_PAGE,
        meta: {
          title: '21grams | Parcel Tracking',
        },
      },
    ],
  },
]

const router = createRouter({ history: createWebHistory(), routes })

router.afterEach((to) => {
  document.title = String(to.meta.title || '21grams | Parcel Tracking')
})
export default router
