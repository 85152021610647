<template>
  <div>
    <EventIcon :status="event.status" />
    <h4>
      {{ capitalizeFilter(event.locationName) }}
    </h4>
    <p class="mb-2 text-black-50">
      {{ eventDate }}
    </p>
    <p class="delivery-route-message">
      {{ event.description }}
    </p>
    <span class="country-name">
      {{ event.countryCode }}
    </span>
  </div>
</template>

<script lang="ts">
import { capitalizeFilter } from '@/helpers/Utils'
import { defineComponent, PropType, Ref, ref } from 'vue'
import EventIcon from '@/views/Components/EventIcon.vue'
import moment from 'moment'
import type { TrackingEvent } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'EventRouteBlock',
  components: { EventIcon },
  props: {
    event: {
      type: Object as PropType<TrackingEvent>,
      required: true,
    },
  },
  setup(props) {
    const eventDate: Ref<string> = ref(moment(props.event.eventTime).format('DD MMM HH:mm'))

    return {
      capitalizeFilter,
      eventDate,
    }
  },
})
</script>
