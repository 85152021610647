import { CARRIERS_NAMES } from '@/consts/Carriers'

export function hexToRGBA(h, opacity = 1) {
  let r = 0
  let g = 0
  let b = 0

  // 3 digits
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`
    g = `0x${h[2]}${h[2]}`
    b = `0x${h[3]}${h[3]}`

    // 6 digits
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`
    g = `0x${h[3]}${h[4]}`
    b = `0x${h[5]}${h[6]}`
  }

  return `rgba(${+r},${+g},${+b},${opacity})`
}

export const capitalizeFilter = (value) => (value ? value.toUpperCase() : value)
export const capitalizeFirstLetter = (value) => (value ? value.charAt(0).toUpperCase() + value.slice(1) : value)
export const carrierName = (value) => CARRIERS_NAMES[value] || value

export const none = () => {}

export const setupApp = async (app?: App) => {
  try {
    const response = await fetch('/version.txt', { cache: 'no-store', method: 'POST' })
    const latestVersion = await response.text()
    const currentVersion = localStorage.getItem('app-version')

    if (latestVersion && currentVersion !== latestVersion) {
      localStorage.setItem('app-version', latestVersion)
      window.location.reload(true) // force reset cache
    } else {
      app?.mount('#app')
    }
  } catch {
    app?.mount('#app')
  }
}
