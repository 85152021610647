import { applyProxyMethodsMixin, ProxyMethods } from '@/proxies/Proxy'

export default class TrackingProxy extends applyProxyMethodsMixin(class {}) implements ProxyMethods {
  public endpoint = 'tracking/v2'

  public async getTrackingData(identifier: string) {
    return this.submit('get', `/${this.endpoint}/by-identifier/${identifier}`)
  }

  public async getProofOfDelivery(identifier: string) {
    return this.submit('get', `/proof-of-delivery/${identifier}`)
  }
}
