export const PACKAGE = 'package'
export const PARCEL = 'parcel'

const getName = (type: string) => {
  switch (type) {
    case PACKAGE:
      return 'Paket'
    case PARCEL:
      return 'Parcel'
    default:
      return ''
  }
}

export const DistributionChannels = {
  PACKAGE,
  PARCEL,
  getName,
}
