<template>
  <svg
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    focusable="false"
    role="img"
    aria-label="icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    class="b-iconstack b-icon bi"
  >
    <g :transform="transform">
      <slot />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BIconstack',
  props: {
    scale: {
      type: [String, Number],
      default: 1,
    },
  },
  setup(props) {
    return {
      transform: `translate(8 8) scale(${props.scale} ${props.scale}) translate(-8 -8)`,
    }
  },
})
</script>

<style scoped lang="scss">
.b-iconstack {
  display: inline-block;
  vertical-align: text-top;
  fill: currentColor;
}
.b-iconstack svg {
  width: 100%;
  height: 100%;
}
</style>
