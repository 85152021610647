export const ASENDIA = 'asendia'
export const CITYMAIL = 'citymail'
export const DEUTSCHEPOST = 'deutschepost'
export const EARLYBIRD = 'earlybird'
export const POSTNORD = 'postnord'
export const DHL_EXPRESS = 'dhl_express'
export const DHL_FREIGHT = 'dhl_freight'
export const BRING = 'bring'
export const UPS = 'ups'

export const CARRIERS_NAMES = {
  [DHL_EXPRESS]: 'DHL Express',
  [DHL_FREIGHT]: 'DHL Freight',
  [POSTNORD]: 'Postnord',
  [ASENDIA]: 'Asendia',
  [CITYMAIL]: 'Citymail',
  [DEUTSCHEPOST]: 'Deutsche Post',
  [EARLYBIRD]: 'Earlybird',
  [BRING]: 'Bring',
  [UPS]: 'UPS',
}
