<template>
  <div>
    <h3 class="mb-3">Proof Of Delivery</h3>
    <img class="rounded" :src="image" alt="Proof Of Delivery" />
    <hr class="tracking-divider" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProofOfDelivery',
  props: {
    image: {
      type: String,
      required: true,
    },
  },
})
</script>
