import Axios from 'axios'

Axios.defaults.baseURL = import.meta.env.VITE_APP_API_LOCATION
Axios.defaults.headers.common.Accept = 'application/json'
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error)
  },
)

export default Axios
