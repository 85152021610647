<template>
  <div>
    <h2>Delivery information</h2>
    <div class="delivery-info position-relative">
      <div v-if="servicePoint">
        <p class="text-black-50 mb-1">Service-point</p>
        <h4>
          {{ servicePoint.name }}, {{ servicePoint.countryCode }}, {{ servicePoint.postalCode }},
          {{ servicePoint.city }},
          {{ servicePoint.addressLine }}
        </h4>
      </div>

      <p class="text-black-50 mb-1">Receiver address</p>
      <h4>
        {{ itemData.recipient.postalCode }} {{ itemData.recipient.city }},
        {{ itemData.recipient.country }}
      </h4>
    </div>

    <hr class="tracking-divider" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ComputedRef } from 'vue'
import type { ServicePoint, TrackingData } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'DeliveryInformation',
  props: {
    itemData: {
      type: Object as PropType<TrackingData>,
      required: true,
    },
  },
  setup(props) {
    const servicePoint: ComputedRef<ServicePoint | null> = computed(() => {
      return props.itemData.servicePoints?.[0] || null
    })

    return {
      servicePoint,
    }
  },
})
</script>
