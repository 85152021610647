export const GREY = '#C8C8C8'
export const BLACK = '#151515'
export const RED = '#FF5252'
export const YELLOW = '#FFCA10'
export const GREEN = '#058880'
export const GREEN_SUCCESS = '#08A762'
export const DARK_GREEN = '#012B2B'

export const COLORS = {
  GREY,
  BLACK,
  RED,
  YELLOW,
  GREEN,
  GREEN_SUCCESS,
  DARK_GREEN,
}
