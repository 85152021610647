<template>
  <b-iconstack scale="1.2">
    <svg width="18" height="18" viewBox="0 0 25 25">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.79368 19.1499C8.43021 18.5977 8.84612 17.8343 8.965 17H14.035C14.1539 17.8343 14.5698 18.5977 15.2063 19.1499C15.8429 19.7022 16.6573 20.0063 17.5 20.0063C18.3427 20.0063 19.1571 19.7022 19.7937 19.1499C20.4302 18.5977 20.8461 17.8343 20.965 17H23V11.056L20 7H17V5C17 4.73478 16.8946 4.48043 16.7071 4.29289C16.5196 4.10536 16.2652 4 16 4H6H3L2 6H6H15V14.05C14.73 14.326 14.505 14.647 14.337 15H8.663C8.42148 14.4905 8.06044 14.0469 7.61061 13.7069C7.16077 13.3669 6.63546 13.1407 6.07938 13.0474C5.52329 12.9541 4.9529 12.9966 4.41675 13.1711C3.8806 13.3457 3.39456 13.6472 3 14.05V12H1V17H2.035C2.15388 17.8343 2.56979 18.5977 3.20632 19.1499C3.84286 19.7022 4.65728 20.0063 5.5 20.0063C6.34272 20.0063 7.15714 19.7022 7.79368 19.1499ZM0 10H1H3H7L8 8H3H1L0 10ZM21 12H17V9H18.992L21 11.715V12ZM18.561 17.5605C18.2796 17.8419 17.898 18 17.5 18C17.102 18 16.7204 17.8419 16.439 17.5605C16.1576 17.2791 15.9995 16.8975 15.9995 16.4995C15.9995 16.1015 16.1576 15.7199 16.439 15.4385C16.7204 15.1571 17.102 14.999 17.5 14.999C17.898 14.999 18.2796 15.1571 18.561 15.4385C18.8424 15.7199 19.0005 16.1015 19.0005 16.4995C19.0005 16.8975 18.8424 17.2791 18.561 17.5605ZM6.88582 15.926C6.9612 16.108 7 16.303 7 16.5C7 16.8978 6.84196 17.2794 6.56066 17.5607C6.27936 17.842 5.89782 18 5.5 18C5.10218 18 4.72064 17.842 4.43934 17.5607C4.15804 17.2794 4 16.8978 4 16.5C4 16.303 4.0388 16.108 4.11418 15.926C4.18956 15.744 4.30005 15.5786 4.43934 15.4393C4.57863 15.3001 4.74399 15.1896 4.92597 15.1142C5.10796 15.0388 5.30302 15 5.5 15C5.69698 15 5.89204 15.0388 6.07403 15.1142C6.25601 15.1896 6.42137 15.3001 6.56066 15.4393C6.69995 15.5786 6.81044 15.744 6.88582 15.926Z"
      />
    </svg>
  </b-iconstack>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BIconstack from '@/views/Elements/Icons/BIconstack.vue'

export default defineComponent({
  name: 'InTransitIcon',
  components: { BIconstack },
})
</script>
