<template>
  <div v-if="lat && lng">
    <h2 id="pickupPoint" class="mb-3">Pickup Point</h2>
    <GoogleMap :api-key="apiKey" :center="center" :zoom="18" map-type-id="roadmap" style="width: 100%; height: 400px">
      <GMarker :options="{ position: center }">
        <InfoWindow>{{ address }}</InfoWindow>
      </GMarker>
    </GoogleMap>

    <div v-if="servicePoint">
      <br />
      <h4>
        {{ servicePoint.name }}, {{ servicePoint.countryCode }}, {{ servicePoint.postalCode }}, {{ servicePoint.city }},
        {{ servicePoint.addressLine }}
      </h4>
    </div>
    <hr class="tracking-divider" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ComputedRef, PropType } from 'vue'
import { GoogleMap, Marker as GMarker, InfoWindow } from 'vue3-google-map'
import type { PickupPoint, ServicePoint, TrackingData } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'AddressMap',
  components: {
    InfoWindow,
    GoogleMap,
    GMarker,
  },
  props: {
    point: {
      type: Object as PropType<PickupPoint | null>,
      required: true,
    },
    itemData: {
      type: Object as PropType<TrackingData>,
      required: true,
    },
  },
  setup(props) {
    const lat: ComputedRef<number | null> = computed(() => props.point?.latitude)
    const lng: ComputedRef<number | null> = computed(() => props.point?.longitude)
    const address: ComputedRef<string | null> = computed(() => props.point?.address || `${lat.value}, ${lng.value}`)
    const servicePoint: ComputedRef<ServicePoint | null> = computed(() => props.itemData.servicePoints[0])

    const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY
    const center = { lat: lat.value, lng: lng.value }

    return {
      lat,
      lng,
      apiKey,
      center,
      address,
      servicePoint,
    }
  },
})
</script>
