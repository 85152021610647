<template>
  <div>
    <div v-if="loading">
      <Spinner>Loading...</Spinner>
    </div>
    <div v-else>
      <h2 class="mb-0">
        <router-link :to="backToSearch" class="text-body back-link">
          <b-icon icon="chevron-left" />
          Search another shipment
        </router-link>
      </h2>
      <div v-if="itemData" class="parcel-info-block">
        <ShipmentTitle :shipment="primaryShipment" :item-data="itemData" />

        <TrackingStepper :status="primaryShipment.lastTrackingEventStatus" />

        <TrackingStatus :event="lastEvent" />

        <DeliveryInformation :item-data="itemData" />

        <ProofOfDelivery v-if="proofOfDelivery" :image="proofOfDelivery" />

        <DimensionsInfo :item-data="itemData" />

        <ShipmentInfo :shipment="primaryShipment" :original-shipment="secondaryShipment" :item-data="itemData" />

        <AddressMap v-if="point" :point="point" :item-data="itemData" />

        <TrackingEvents v-if="trackingEvents.length" :events="trackingEvents" />
      </div>
      <div v-else>
        <h2 class="mt-4 mb-0 text-black-50 parcel-direction">Sorry, we can't find this tracking number</h2>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { none } from '@/helpers/Utils'
import { useStore } from 'vuex'
import { ref, computed, onMounted, ComputedRef } from 'vue'
import { useRoute } from 'vue-router'
import { SEARCH_PAGE } from '@/consts/Router'
import type { PickupPoint, Shipment, TrackingData, TrackingEvent } from '@/types/Models/Shipment'
import moment from 'moment'
import DeliveryInformation from '@/views/Components/DeliveryInformation.vue'
import ProofOfDelivery from '@/views/Components/ProofOfDelivery.vue'
import DimensionsInfo from '@/views/Components/DimensionsInfo.vue'
import TrackingStepper from '@/views/Components/TrackingStepper.vue'
import TrackingStatus from '@/views/Components/TrackingStatus.vue'
import TrackingEvents from '@/views/Components/TrackingEvents.vue'
import ShipmentTitle from '@/views/Components/ShipmentTitle.vue'
import ShipmentInfo from '@/views/Components/ShipmentInfo.vue'
import Spinner from '@/views/Elements/Spinner.vue'
import BIcon from '@/views/Elements/Icons/BIcon.vue'
import AddressMap from '@/views/Components/AddressMap.vue'

export default {
  components: {
    BIcon,
    Spinner,
    AddressMap,
    ShipmentInfo,
    ShipmentTitle,
    DimensionsInfo,
    TrackingStatus,
    TrackingStepper,
    TrackingEvents,
    ProofOfDelivery,
    DeliveryInformation,
  },
  setup() {
    const loading = ref(true)
    const route = useRoute()
    const store = useStore()
    const identifier: string = route.params.id

    const itemData: ComputedRef<TrackingData> = computed(() => store.getters['tracking/trackingInfo'])
    const proofOfDelivery: ComputedRef<string | null> = computed(() => store.getters['tracking/proofOfDelivery'])
    const point: ComputedRef<PickupPoint | null> = computed(() => itemData.value.pickupPoint)

    const trackingEvents: ComputedRef<TrackingEvent[]> = computed(() => {
      const allEvents = [
        ...(itemData.value?.shipments[0]?.trackingEvents || []),
        ...(itemData.value?.shipments[1]?.trackingEvents || []),
      ]
      return allEvents.sort((a: TrackingEvent, b: TrackingEvent) => moment(b.eventTime).diff(moment(a.eventTime)))
    })

    const lastEvent: ComputedRef<TrackingEvent | null> = computed(() =>
      trackingEvents.value.length ? trackingEvents.value[0] : null,
    )

    const primaryShipment: ComputedRef<Shipment> = computed(() => {
      return [...(itemData.value?.shipments || [])].sort((a, b) => b.runningNumber - a.runningNumber)[0]
    })

    const secondaryShipment: ComputedRef<Shipment | null> = computed(() => {
      return itemData.value?.shipments.find((s: Shipment) => s !== primaryShipment.value)
    })

    onMounted(() => {
      loading.value = true
      store
        .dispatch('tracking/fetch', identifier)
        .then((res) => {
          let trackingNumber = identifier
          if (res.shipments) {
            const shipment = res.shipments[res.shipments.length - 1]
            trackingNumber = shipment.shipmentNumber
          }
          store.dispatch('tracking/proofOfDelivery', trackingNumber).then(none)
        })
        .finally(() => {
          loading.value = false
        })
    })

    return {
      loading,
      proofOfDelivery,
      itemData,
      point,
      trackingEvents,
      lastEvent,
      primaryShipment,
      secondaryShipment,
      backToSearch: { name: SEARCH_PAGE },
    }
  },
}
</script>

<style lang="scss" scoped></style>
