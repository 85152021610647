<template>
  <div class="tracking-footer text-center"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageFooter',
})
</script>

<style scoped lang="scss">
.tracking-footer {
  height: 150px;
}
.tracking-header {
  padding-top: 64px;
  margin-bottom: 48px;
  .logo {
    width: 80px;
    height: 80px;
  }
}
</style>
