<template>
  <div>
    <ul class="parcel-info">
      <li>
        <span class="d-block text-black-50">From</span>
        {{ from }}
      </li>
      <li>
        <span class="d-block text-black-50">Carrier</span>
        {{ carrierName(shipment.carrier) }}
        <small v-if="forwardedBy" class="text-black-50"> (forwarded by {{ carrierName(forwardedBy) }}) </small>
      </li>
      <template v-if="showMore">
        <li>
          <span class="d-block text-black-50">Shipment ID</span>
          {{ shipment.shipmentNumber }}
        </li>
        <li>
          <span class="d-block text-black-50">Service type</span>
          {{ capitalizeFirstLetter(itemData.service) }}
        </li>
        <li v-if="additionalServices">
          <span class="d-block text-black-50">Additional services</span>
          {{ additionalServices }}
        </li>
      </template>
    </ul>

    <b-button variant="link" class="p-0 expand-list" :class="{ active: showMore }" @click="toggleShowMore">
      {{ moreBtnText }}
      <expand-icon />
    </b-button>

    <hr class="tracking-divider" />
  </div>
</template>

<script lang="ts">
import { capitalizeFirstLetter, carrierName } from '@/helpers/Utils'
import { defineComponent, ref, computed, PropType } from 'vue'
import type { Shipment, TrackingData } from '@/types/Models/Shipment'
import ExpandIcon from '@/views/Elements/Icons/ExpandIcon.vue'

export default defineComponent({
  name: 'ShipmentInfo',
  components: { ExpandIcon },
  props: {
    originalShipment: {
      type: Object as PropType<Shipment | null>,
      required: false,
      default: null,
    },
    itemData: {
      type: Object as PropType<TrackingData>,
      required: true,
    },
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const showMore = ref(false)

    const moreBtnText = computed(() => (showMore.value ? 'View less' : 'View more'))
    const forwardedBy = computed(() => props.originalShipment?.carrier)
    const from = computed(() => props.itemData?.sender?.company || props.itemData?.sender?.name)

    const additionalServices = computed(() => {
      if (props.itemData.distributionChannel === 'package') {
        if (props.itemData.servicePoints.length > 0) {
          return 'Service-point delivery'
        }
        return 'Leave at the door'
      }

      if (props.itemData.distributionChannel === 'parcel') {
        if (props.itemData.deliveryType === 'service_point') {
          return 'Service-point delivery'
        }
        if (props.itemData.deliveryType === 'address') {
          return 'Address delivery'
        }
      }

      return null
    })

    const toggleShowMore = () => {
      showMore.value = !showMore.value
    }

    return {
      from,
      showMore,
      moreBtnText,
      forwardedBy,
      additionalServices,
      carrierName,
      toggleShowMore,
      capitalizeFirstLetter,
    }
  },
})
</script>
