<template>
  <v-layout>
    <div class="privacy">
      <h1 class="privacy-title">Personal data processing agreement</h1>
      <div>
        <div class="privacy-section">
          <h2 class="privacy-sub-title">1. Initial provisions</h2>
          <p class="privacy-text">
            1.1 The Parties have entered into agreement regarding services under which the Data Processor (Supplier)
            will Process Personal Data on behalf of the Data Controller (Buyer). This Personal Data Processing Agreement
            (hereinafter the “Agreement”
          </p>
          <p class="privacy-text">
            1.2 The Parties have entered into this Agreement in order to adduce adequate safeguards with respect to the
            protection of privacy and fundamental rights and freedoms of individuals for the transfer by the Data
            Controller to the Data Processor of Personal Data and the Processing of Personal Data by the Data Processor
            on behalf of the Data Controller. This Agreement is intended to fulfil the requirement of a written
            agreement that governs the Processing Personal Data by the Data Processor on behalf of the Data Controller.
            If not explicitly stated otherwise, the terms and conditions of the Main Agreement and t he General Terms
            and Conditions shall also apply to the Agreement. The limitations of liability stated in the Main Agreement
            and the General Terms and Conditions shall thereby apply on the Data Processor’s eventual liability for
            damages towards the Data Controller for breaches of this Agreement.
          </p>
          <p class="privacy-text">
            1.3 The specific terms and expression relating to the Processing of Personal Data not defined in this
            Agreement shall have the same meaning as the terms and expressions in the Personal Data Legislation
            applicable at the time of the Processing of the Personal Data.
          </p>
        </div>
        <div class="privacy-section">
          <h2 class="privacy-sub-title">2. Definitions</h2>
          <h3 class="privacy-sub-point">The following terms, used in this Agreement have the following meanings:</h3>
          <h3 class="privacy-sub-point">“Processing (of Personal Data)”</h3>
          <p class="privacy-text">Shall have the same meaning as in applicable Personal Data Legislation;</p>
          <h3 class="privacy-sub-point">“Personal Data”</h3>
          <p class="privacy-text">
            Shall have the same meaning as in applicable Personal Data Legislation and constitutes the data that the
            Data Controller is responsible for and which the Data Processor shall Process in accordance with the
            Agreement;
          </p>
          <h3 class="privacy-sub-point">”Personal Data Breach”</h3>
          <p class="privacy-text">Shall have the same meaning as in applicable Personal Data Legislation;</p>
          <h3 class="privacy-sub-point">”Personal Data Legislation”</h3>
          <p class="privacy-text">
            Means the at each time applicable Swedish legislation regarding the Processing of Personal Data or the
            common EU rules on data protection, especially the Swedish Personal Data Act (personuppgiftslagen, SFS
            1998:204) and the EU regulation 2016/679 of 27 April 2016 on the protection of natural persons with regard
            to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC,
            which shall be applied from 25 May 2018 and will replace the Swedish Personal Data Act;
          </p>
          <h3 class="privacy-sub-point">”Third Country”</h3>
          <p class="privacy-text">
            Means a state that is not included in the European Union or part of the European Economic Area.
          </p>
        </div>
        <div class="privacy-section">
          <h2 class="privacy-sub-title">3. Obligations of the data processor</h2>
          <p class="privacy-text">
            3.1 The Data Processor shall comply with what is set forth in Appendix A to this Agreement, INSTRUCTIONS TO
            THE DATA PROCESSOR. In addition to what otherwise follows from the Agreement, the Data Processor agrees and
            warrants:
          </p>
          <p class="privacy-text">
            a) that the Processing of Personal Data is carried out in accordance with the applicable Personal Data
            Legislation;
          </p>
          <p class="privacy-text">
            b) to assist the Data Controller in ensuring compliance with the obligations deriving from applicable
            Personal Data Legislation, taking into account the nature of Processing and the information available to the
            Data Processor;
          </p>
          <p class="privacy-text">
            c) to Process the Personal Data only on behalf of the Data Controller and in compliance with the Data
            Controller’s documented instructions (which for the sake of clarity shall imply that the Processing is
            carried out only for the purposes decided by the Data Controller, as set forth in Appendix A.). If the Data
            Processor cannot comply with such instructions for whatever reasons, the Data Processor shall promptly
            inform the Data Controller of its inability to comply, in which case the Data Controller is entitled to
            suspend the Processing, request the immediate return of the Personal Data and/or terminate the Agreement;
          </p>
          <p class="privacy-text">
            d) that it will immediately inform the Data Controller if, in the Data Processors opinion, an instruction
            infringes the applicable Personal Data Legislation;
          </p>
          <p class="privacy-text">
            e) to implement appropriate technical and organisational measures to ensure and demonstrate that Personal
            Data is not Processed in an illegal or unlawful manner or in a way which risk that they fall into the wrong
            hands, and ensure
          </p>
          <p class="privacy-text">
            that such measures (taking into account the technological developments, the cost of implementing any
            measures, the nature, scope, context and purposes of the Processing) maintain a level of security which is
            appropriate in relation to the risk for the rights and freedoms of natural persons;
          </p>
          <p class="privacy-text">
            f) to maintain records of all categories of Processing performed on behalf of the Data Controller, including
            name and contact details, and where applicable, transfers of Personal Data to a Third Country or
            international organisation and, where possible, a general description of the technical and organisational
            security measures;
          </p>
          <p class="privacy-text">
            g) to keep authorisation controls and logging of the systems where Personal Data is Processed, for the
            purpose of making it possible for the Data Controller to audit and ensure the integrity and confidentiality
            of the Personal Data, including safeguards against accidental or unlawful destruction, loss, alteration,
            unauthorised disclosure of, or access to Processed Personal Data;
          </p>
          <p class="privacy-text">
            h) to deal promptly and properly with all inquiries from the Data Controller relating to its Processing of
            the Personal Data and provide the Data Controller with all information required to demonstrate its
            compliance with obligations deriving from applicable Personal Data Legislation;
          </p>
          <p class="privacy-text">
            i) to ensure that only authorised persons can Process Personal Data, by keeping authorisation controls and
            logging of the systems where Personal Data is Processed, and ensure that these persons have committed
            themselves to confidentiality or are under an appropriate statutory obligation of confidentiality;
          </p>
          <p class="privacy-text">
            j) that it will without undue delay refer any third party requesting information relating to the Personal
            Data to the Data Controller, unless such reference is prohibited under criminal law (e.g. to preserve the
            confidentiality of a law enforcement investigation) and on request cooperate with relevant supervisory
            authority in the performance of its tasks and without undue delay inform the Data Controller of this;
          </p>
          <p class="privacy-text">
            k) to assist the Data Controller through appropriate technical and organisational measures, to the extent
            possible, so that the Data Controller can fulfil its obligation to respond to a request following a data
            subject exercising its rights under applicable Personal Data Legislation;
          </p>
          <p class="privacy-text">
            l) to, at the request of the Data Controller, taking into account the type of Processing and the information
            available to the Data Processor, assist to comply with the obligations regarding the preparation of a data
            protection impact assessment and prior consultation with the responsible supervisory authority, in
            accordance with applicable Personal Data Legislation;
          </p>
          <p class="privacy-text">
            n) not to transfer Personal Data to a Third Country unless approved in writing by the Data Processor
          </p>
          <p class="privacy-text">
            o) to, at the request of the Data Controller, submit its facilities where Personal Data is Processed for
            audit in order to ensure and demonstrate that the Processing is compliant with this Agreement and the
            obligations deriving from applicable Personal Data Legislation. The audit shall be carried out by the Data
            Controller or such person that the Data Controller appoints, provided that such person is bound by a duty of
            confidentiality.
          </p>
        </div>
        <div class="privacy-section">
          <h2 class="privacy-sub-title">4. Sub-processing</h2>
          <p class="privacy-text">
            4.1 The Data Processor is not entitled to sub-contract the Processing of the Personal Data without a
            specific or general consent from the Data Controller.
          </p>
          <p class="privacy-text">
            4.2 If the Data Processor engages a sub-processor, the Data Processor shall guarantee that such
            sub-processor’s Processing is performed in accordance with the Data Processors obligations under section 3
            of this Agreement.
          </p>
          <p class="privacy-text">
            4.3 The Data Processor shall maintain records of sub-processors who are Processing Personal Data and shall
            at the request of the Data Controller provide a list of these.
          </p>
          <p class="privacy-text">
            4.4 If the Data Processor engages a sub-processor it agrees to without delay inform the Data Controller of
            the identity of the sub-processor, and, upon the request of the Data Controller, where the Personal Data
            will be Processed as well as other relevant information related thereto such as a copy of the agreement
            entered into between the Data Processor and such sub-processor.
          </p>
          <p class="privacy-text">
            4.5 The Data Processor shall remain fully liable to the Data Controller for the performance of any
            sub-processor.
          </p>
        </div>
        <div class="privacy-section">
          <h2 class="privacy-sub-title">5. Obligation after the termination of services</h2>
          <p class="privacy-text">
            5.1 The Parties agree that on the termination of the Main Agreement, the following shall apply. The Data
            Processor shall (and, where applicable, ensure that the sub-processor shall), return all Personal Data to
            the Data Controller or, at the request of the Data Controller, destroy all Personal Data, unless the Data
            Processor is required to store the Personal Data under Union or national law. If the Data Controller decides
            that Personal data shall be destroyed the Data Processor shall confirm to the Data Controller when
            completed.
          </p>
          <p class="privacy-text">
            5.2 In the event that legislation imposed upon the Data Processor prevents it from returning or destroying
            all or part of the Personal Data, the Data Processor warrants that it will guarantee the confidentiality of
            the Personal Data and that it will not actively Process the Personal Data or, alternatively, anonymize the
            Personal Data in a manner that makes it impossible to recreate the Personal Data in such a manner that a
            natural person is not or no longer identifiable.
          </p>
        </div>
        <div class="privacy-section">
          <h2 class="privacy-sub-title">6. Term and termination</h2>
          <p class="privacy-text">
            6.1 This Agreement shall enter into force when signed by the Parties and shall remain valid until the Main
            Agreement is terminated.
          </p>
          <p class="privacy-text">
            6.2 A Party is entitled to terminate this Agreement with immediate effect should the other Party commit a
            material breach of its obligations pursuant to this Agreement which is not remedied within thirty (30) days
            of such Party being notified of such breach by the non-defaulting Party. A material breach of this Agreement
            shall always also constitute a material breach of the Main Agreement. 6.3 A Party’s notification of
            immediate termination according to section 6.2 shall be sent without undue delay after the event which gives
            rise to the notification has become known for such Party.
          </p>
          <p class="privacy-text">
            6.3 Termination of this Agreement shall be without prejudice to any rights and obligations of either Party
            against the other which may have accrued up to the date of such termination.
          </p>
        </div>
        <div class="privacy-section">
          <h2 class="privacy-sub-title">
            7. Handling and notification of a personal data breach to the supervisory authority
          </h2>
          <p class="privacy-text">
            7.1 The Data Processor shall without undue delay notify the Data Controller’s designated contact person
            after having become aware of a Personal Data Breach. If and to the extent not possible to provide all
            information at once, the Data Processor may provide the Data Controller with the information in batches
            without further delay.
          </p>
          <p class="privacy-text">
            7.2 The Data Processor shall without undue delay investigate a Personal Data Breach and undertake measures
            to mitigate possible adverse effects of the Personal Data Breach, identify its cause(s) and prevent similar
            Personal Data Breaches. The Data Processor shall cooperate with the Data Controller in order to protect the
            rights and freedoms of registered natural persons. The Parties undertake to coordinate remedial and
            mitigating action which are undertaken and planned.
          </p>
        </div>
        <div class="privacy-section">
          <h2 class="privacy-sub-title">8. Additions and amendments</h2>
          <p class="privacy-text">
            8.1 No amendments or additions to this Agreement may be made except in writing, duly signed by each of the
            Parties.
          </p>
        </div>
        <div class="privacy-section">
          <h2 class="privacy-sub-title">Appendixa - instructions the data processer</h2>
          <h3 class="privacy-sub-point">1. Purpose with the processing</h3>
          <p class="privacy-text">
            The purpose with the Processing is to optimise logistics concerning the physical circulars by post
            dispatched by the Data Controller.
          </p>
          <h3 class="privacy-sub-point">2. Categories of data subjects</h3>
          <p class="privacy-text">
            The categories of data subjects affected are usually the registered individuals in the customer registry of
            the Data Controller.
          </p>
          <h3 class="privacy-sub-point">3. Personal data object to processing</h3>
          <p class="privacy-text">
            The Personal Data that may be Processed are name, address, zip code, city, country and, if applicable, a
            customer category.
          </p>
          <h3 class="privacy-sub-point">4. Sensitive personal data</h3>
          <p class="privacy-text">
            No sensitive Personal Data will be subject to Processing as a part of the Data Processor’s service.
          </p>
          <h3 class="privacy-sub-point">5. Personal identification number</h3>
          <p class="privacy-text">
            Information containing personal identification numbers will not be subject to Processing.
          </p>
          <h3 class="privacy-sub-point">6. Criminal offenses</h3>
          <p class="privacy-text">Information containing criminal offenses will not be subject to Processing.</p>
          <h3 class="privacy-sub-point">7. Security</h3>
          <p class="privacy-text">
            The Data Processor shall ensure that files can be received and returned through means of communication that
            provides for confidentiality, e.g. communication encrypted with SSL (Secure socket layer) over file transfer
            protocols such as SFTP and HTTPS. Communication between system components on the open web shall be
            confidential by applying SSL.
          </p>
          <h3 class="privacy-sub-point">8. Access to the register</h3>
          <p class="privacy-text">
            The Data Processor shall ensure that no other than authorised personnel, which in this case shall mean staff
            in the division ”Production management & Support” and the technical staff at the IT and development
            department, has access to the personal data in the address file subject to Processing in conjunction with
            the circulars.
          </p>
          <h3 class="privacy-sub-point">9. Sub-processor</h3>
          <p class="privacy-text">
            The Data Processor has a general consent from the Data Controller to sub-contract sub-processors. However,
            the Data Processor must in such cases inform the Data Controller of any possible sub-processor that may be
            used, providing the Data Controller an opportunity to object Processing by such sub-processor.
            <br />
            <br />
            Postal operators that are approved by the Swedish Post and Telecom Authority (Sw. post- och telestyrelsen)
            are to be regarded as, by the Data Processor, approved sub-processors of the Sub-processor. These
            sub-processers are listed on https://www.pts.se/en/english-b/post/postal-operators/, under the sub-folder
            ”List of postal operators”. The postal operators Posti OY in Finland, Norpost Distribution AS och Helthjem
            Mediapost AS in Norway and Bladkompagniet A/S in Denmark are approved sub-processors.
          </p>
          <h3 class="privacy-sub-point">10. Place of processing</h3>
          <p class="privacy-text">
            The Processing may take place at the Data Processor and the sub-processors, on their servers within the EU
            in a local or cloud environment.
          </p>
          <h3 class="privacy-sub-point">11. Transfer to third countries</h3>
          <p class="privacy-text">
            The Personal Data may be transferred to Third Countries if the Data Processor ensures that sufficient
            security levels are provided through e.g. agreements between the Data Processor and the sub-processors based
            on standardised data protection provisions adopted by a supervisory authority and approved by the
            commission, so called Standard Contract Clauses. The Data Processor shall upon the Data Controller’s request
            present that such document has been signed by the sub-processor.
          </p>
          <h3 class="privacy-sub-point">12. Sorting out</h3>
          <p class="privacy-text">
            Personal Data will be Processed as long as necessary for the purpose of providing the service under the Main
            Agreement, however no more than 90 days from receiving the file to be processed.
          </p>
          <h3 class="privacy-sub-point">13. Audit</h3>
          <p class="privacy-text">
            The Data Controller has once a year the right to conduct visits for the purpose of auditing the Data
            Processors Processing. All costs associated with such audit shall be borne by the Data Controller. The
            Sub-processors shall upon request present sub-processing agreements regarding the sub-processors of the Data
            Processor.
          </p>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import VLayout from '@/views/Layouts/DefaultLayout.vue'

export default defineComponent({
  name: 'TermsOfUsePage',
  components: {
    VLayout,
  },
})
</script>

<style lang="scss" scoped>
.page-body {
  background-color: #f5f5f3 !important;
}

.bg-light {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}
</style>
