<template>
  <b-iconstack scale="1.2">
    <svg width="18" height="18" viewBox="0 0 25 25">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.85577 0.307473C9.20518 0.105462 9.5997 0 10 0C10.4003 0 10.7948 0.105462 11.1442 0.307473C11.4938 0.509567 11.7871 0.801955 11.9924 1.15765L19.6844 14.4821C19.8902 14.8386 19.9997 15.245 20 15.6603C20.0003 16.0756 19.8915 16.4822 19.6863 16.839C19.4812 17.1956 19.1878 17.4889 18.8379 17.6916C18.488 17.8942 18.0929 18 17.692 18H2.30802C1.90708 18 1.51194 17.8942 1.16214 17.6916C0.812188 17.4889 0.518769 17.1956 0.31368 16.839C0.108482 16.4822 -0.000341704 16.0756 8.06021e-07 15.6603C0.000343316 15.245 0.109838 14.8386 0.315614 14.4821L8.0076 1.15765C8.21294 0.801954 8.50622 0.509566 8.85577 0.307473ZM10 1.8702C9.90969 1.8702 9.82224 1.89408 9.74664 1.93779C9.67118 1.98142 9.61137 2.04248 9.57094 2.11252L1.87895 15.437C1.83857 15.507 1.81825 15.5844 1.81818 15.6619C1.81812 15.7393 1.83831 15.8168 1.87857 15.8868C1.91894 15.957 1.97876 16.0182 2.0543 16.062C2.12998 16.1058 2.21757 16.1298 2.30802 16.1298H17.692C17.7824 16.1298 17.87 16.1058 17.9457 16.062C18.0212 16.0182 18.0811 15.957 18.1214 15.8868C18.1617 15.8168 18.1819 15.7393 18.1818 15.6619C18.1818 15.5845 18.1614 15.507 18.121 15.437L10.4291 2.11252C10.3886 2.04248 10.3288 1.98142 10.2534 1.93779C10.1778 1.89408 10.0903 1.8702 10 1.8702Z"
      />
    </svg>
  </b-iconstack>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BIconstack from '@/views/Elements/Icons/BIconstack.vue'

export default defineComponent({
  name: 'ErrorIcon',
  components: { BIconstack },
})
</script>
