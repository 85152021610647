<template>
  <router-view :key="route.fullPath" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { setupApp } from '@/helpers/Utils'

export default defineComponent({
  name: 'TrackingApp',
  setup() {
    const route = useRoute()

    setInterval(() => {
      setupApp()
    }, 60000)

    return {
      route,
    }
  },
})
</script>
