/* ============
 * Main File
 * ============
 *
 * Will initialize the application.
 */

import { createApp } from 'vue'
import { createBootstrap } from 'bootstrap-vue-next'
import { setupApp } from '@/helpers/Utils'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

// styles
import 'font-awesome/css/font-awesome.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import '@/assets/scss/main.scss'

// Plugins
import Sentry from '@/plugins/sentry'

const app = createApp(App)

app.use(createBootstrap())
app.use(Sentry)
app.use(router)
app.use(store)
;(async () => {
  await setupApp(app)
})()
