<template>
  <i class="bi b-icon bi-iconstack">
    <svg width="15" height="15" viewBox="0 0 15 15">
      <path
        d="M5.57143 0C7.04906 0 8.46618 0.586988 9.51102 1.63183C10.5559 2.67668 11.1429 4.09379 11.1429 5.57143C11.1429 6.95143 10.6371 8.22 9.80571 9.19714L10.0371 9.42857H10.7143L15 13.7143L13.7143 15L9.42857 10.7143V10.0371L9.19714 9.80571C8.22 10.6371 6.95143 11.1429 5.57143 11.1429C4.09379 11.1429 2.67668 10.5559 1.63183 9.51102C0.586988 8.46618 0 7.04906 0 5.57143C0 4.09379 0.586988 2.67668 1.63183 1.63183C2.67668 0.586988 4.09379 0 5.57143 0ZM5.57143 1.71429C3.42857 1.71429 1.71429 3.42857 1.71429 5.57143C1.71429 7.71429 3.42857 9.42857 5.57143 9.42857C7.71429 9.42857 9.42857 7.71429 9.42857 5.57143C9.42857 3.42857 7.71429 1.71429 5.57143 1.71429Z"
      />
    </svg>
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SearchIcon',
})
</script>

<style scoped lang="scss">
.bi-iconstack {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: text-top;
  fill: currentColor;
}
.bi-iconstack svg {
  width: 100%;
  height: 100%;
}
</style>
