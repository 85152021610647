<template>
  <b-iconstack scale="1.2">
    <svg width="18" height="18" viewBox="0 0 25 25">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 3C3.89543 3 3 3.89543 3 5L5 5H6H9V11.9209L12 10.0002L15 11.9209V5H19V19H6H5H3C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM7 7H1L0 9H6L7 7ZM2 11H7L6 13H1L2 11ZM6 17L7 15H3L2 17H6Z"
      />
    </svg>
  </b-iconstack>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BIconstack from '@/views/Elements/Icons/BIconstack.vue'

export default defineComponent({
  name: 'OutForDeliveryIcon',
  components: { BIconstack },
})
</script>
