<template>
  <div class="tracking-header">
    <div class="logo mx-auto">
      <router-link :to="mainPage" target="_blank">
        <img src="../../../assets/images/logo.svg" alt="" />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { SEARCH_PAGE } from '@/consts/Router'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageHeader',
  setup() {
    return {
      mainPage: { name: SEARCH_PAGE },
    }
  },
})
</script>

<style scoped lang="scss">
.tracking-header {
  padding-top: 64px;
  margin-bottom: 48px;
  .logo {
    width: 80px;
    height: 80px;
  }
}
</style>
