export const INFO_RECEIVED = 'info_received'
export const IN_TRANSIT = 'in_transit'
export const OUT_FOR_DELIVERY = 'out_for_delivery'
export const DELIVERED = 'delivered'
export const EXCEPTION = 'exception'
export const FAILED_ATTEMPT = 'failed_attempt'
export const RETURNED = 'returned'

export default class trackingEventStatuses {
  /**
   * @returns {(string)[]}
   */
  static get map() {
    return [INFO_RECEIVED, IN_TRANSIT, OUT_FOR_DELIVERY, DELIVERED, EXCEPTION, FAILED_ATTEMPT]
  }
}
