<template>
  <b-iconstack scale="1.2">
    <svg width="18" height="18" viewBox="0 0 25 25">
      <path
        d="M16.5827 21.0022L14 18.1746L15.0894 17.0813L16.5827 18.5799L19.9106 15.1953L21 16.5243L16.5827 21.0022Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 17V15H11V17H13ZM19 13V5H5V19H11V21H5C4.86 21 4.73 20.99 4.6 20.97C4.21 20.89 3.86 20.69 3.59 20.42C3.41 20.23 3.26 20.02 3.16 19.78C3.06 19.54 3 19.27 3 19V5C3 4.72 3.06 4.46 3.16 4.23C3.26 3.99 3.41 3.77 3.59 3.59C3.86 3.32 4.21 3.12 4.6 3.04C4.73 3.01 4.86 3 5 3H9.18C9.6 1.84 10.7 1 12 1C13.3 1 14.4 1.84 14.82 3H19C20.1 3 21 3.9 21 5V13H19ZM13 13V7H11V13H13ZM12.75 3.5C12.75 3.09 12.41 2.75 12 2.75C11.59 2.75 11.25 3.09 11.25 3.5C11.25 3.91 11.59 4.25 12 4.25C12.41 4.25 12.75 3.91 12.75 3.5Z"
      />
    </svg>
  </b-iconstack>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BIconstack from '@/views/Elements/Icons/BIconstack.vue'

export default defineComponent({
  name: 'InfoReceivedIcon',
  components: { BIconstack },
})
</script>
