<template>
  <div>
    <h2 class="tracking-status-text">
      {{ statusName }}
    </h2>
    <h4 class="text-black-50 tracking-desc">
      {{ statusDescription }}
    </h4>

    <hr class="tracking-divider" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { TrackingEvent } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'TrackingStatus',
  props: {
    event: {
      type: Object as PropType<TrackingEvent | null>,
      required: true,
    },
  },
  setup(props) {
    const statusName = computed(() => {
      return props.event?.status ? props.event.status.replaceAll('_', ' ') : 'Booked'
    })

    const statusDescription = computed(() => {
      return props.event?.status && props.event.description
        ? props.event.description
        : 'The sender has sent information on a shipment item with this ID, but we have not yet received the shipment item. If you have any questions, please contact the sender.'
    })

    return {
      statusName,
      statusDescription,
    }
  },
})
</script>
