<template>
  <div>
    <h2 class="mt-4 mb-0 text-black-50 parcel-direction">
      {{ type }}
      <span class="text-body">{{ shipment.shipmentNumber }}</span>
      from
      <span class="text-body">{{ from }}</span>
    </h2>
    <hr class="tracking-divider" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ComputedRef } from 'vue'
import { DistributionChannels } from '@/consts/DistributionChannels'
import { Shipment, TrackingData } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'ShipmentTitle',
  props: {
    itemData: {
      type: Object as PropType<TrackingData>,
      required: true,
    },
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const type: ComputedRef<string> = computed(() => {
      return DistributionChannels.getName(props.itemData.distributionChannel)
    })

    const from: ComputedRef<string> = computed(() => {
      return props.itemData?.sender?.company || props.itemData?.sender?.name || ''
    })

    return {
      type,
      from,
    }
  },
})
</script>
