<template>
  <b-iconstack scale="1.2">
    <svg width="18" height="18" viewBox="0 0 25 25">
      <path
        d="M16.0214 21L13.4286 18.0238L14.5223 16.873L16.0214 18.4504L19.4063 14.8889L20.5 16.2877L16.0214 21ZM19.2 3C20.2371 3 21 3.89286 21 4.98413V14C20.5523 14 19.8 14 19 14V4.98413H15V11.9206L12 10L9 11.9206V4.98413H5V19H11C11 19.7525 11 20.263 11 21H4.88571C3.84857 21 3 19.9484 3 18.8571V4.98413C3 3.89286 3.84857 3 4.88571 3H19.2Z"
      />
    </svg>
  </b-iconstack>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BIconstack from '@/views/Elements/Icons/BIconstack.vue'

export default defineComponent({
  name: 'DeliveredIcon',
  components: { BIconstack },
})
</script>
