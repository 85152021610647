import { SEARCH_PAGE } from '@/consts/Router'
import router from '@/router'
import TrackingProxy from '@/proxies/TrackingProxy'
import { none } from '@/helpers/Utils'
import type { ShipmentResponse } from '@/types/Models/Shipment'

export const SET_DATA = 'SET_DATA'
export const SET_POD = 'SET_POD'

const actions = {
  fetch: ({ commit }, number) =>
    new TrackingProxy()
      .getTrackingData(number)
      .then((response: ShipmentResponse) => {
        commit(SET_DATA, response.data)
        return response.data
      })
      .catch(() => {
        router.push({ name: SEARCH_PAGE }).then(none)
      }),
  proofOfDelivery: ({ commit }, number) => {
    new TrackingProxy()
      .getProofOfDelivery(number)
      .then((response: any) => {
        commit(SET_POD, response.url)
      })
      .catch(none)
  },
}

const state = {
  trackingInfo: null,
  proofOfDelivery: null,
}

const getters = {
  trackingInfo: (state) => state.trackingInfo,
  proofOfDelivery: (state) => state.proofOfDelivery,
}

const mutations = {
  [SET_DATA](state, trackingInfo) {
    state.trackingInfo = trackingInfo
  },
  [SET_POD](state, pod) {
    state.proofOfDelivery = pod
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
