<template>
  <ul class="tracking-status d-flex justify-content-between">
    <template v-for="event in pipelineDefault" :key="`pipeline-item-${event.name}`">
      <li :class="{ active: event.active }">
        {{ capitalizeFirstLetter(event.name) }}
      </li>
    </template>
  </ul>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import {
  DELIVERED,
  EXCEPTION,
  IN_TRANSIT,
  INFO_RECEIVED,
  OUT_FOR_DELIVERY,
  RETURNED,
} from '@/consts/TrackingEventStatuses'
import { capitalizeFirstLetter } from '@/helpers/Utils'

export default defineComponent({
  name: 'TrackingStepper',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const pipelineDefault = computed(() => {
      return {
        INFO_RECEIVED: {
          name: 'Booked',
          active: true,
        },
        IN_TRANSIT: {
          name: 'In transit',
          active: [IN_TRANSIT, OUT_FOR_DELIVERY, DELIVERED, EXCEPTION, RETURNED].includes(props.status),
        },
        OUT_FOR_DELIVERY: {
          name: 'Out for delivery',
          active: [OUT_FOR_DELIVERY, DELIVERED, RETURNED].includes(props.status),
        },
        DELIVERED: {
          name: 'Delivered',
          active: [DELIVERED, RETURNED].includes(props.status),
        },
      }
    })

    return {
      pipelineDefault,
      capitalizeFirstLetter,
    }
  },
})
</script>
