/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */

import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modules
import tracking from '@/store/modules/tracking'

const debug = import.meta.env.VITE_ENV !== 'production'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})
const store = createStore({
  /**
   * Assign the modules to the store.
   */
  modules: {
    tracking,
  },

  /**
   * If strict mode should be enabled.
   */
  strict: debug,

  /**
   * Plugins used in the store.
   */
  plugins: [vuexLocal.plugin],
})

export default store
