<template>
  <div class="wrap-tracking">
    <div class="tracking-container">
      <div class="d-flex flex-column justify-content-between mh-100">
        <PageHeader />
        <div class="tracking-content">
          <slot />
        </div>
        <PageFooter />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import PageHeader from '@/views/Layouts/Parts/PageHeader.vue'
import PageFooter from '@/views/Layouts/Parts/PageFooter.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    PageHeader,
    PageFooter,
  },
})
</script>
