<template>
  <div>
    <span v-b-tooltip="{ title: text, placement: placement }" class="pt-2 pb-2">
      <slot />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
  },
})
</script>
