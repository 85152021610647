<template>
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.41 0.578125L6 5.16813L10.59 0.578125L12 1.99813L6 7.99813L0 1.99813L1.41 0.578125Z" fill="#058880" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ExpandIcon',
})
</script>
