import {
  DELIVERED,
  EXCEPTION,
  FAILED_ATTEMPT,
  IN_TRANSIT,
  INFO_RECEIVED,
  OUT_FOR_DELIVERY,
} from '@/consts/TrackingEventStatuses'
import { GREEN, GREEN_SUCCESS, RED } from '@/consts/Colors'
import InfoReceivedIcon from '@/views/Elements/Icons/TrackingStatus/InfoReceivedIcon.vue'
import InTransitIcon from '@/views/Elements/Icons/TrackingStatus/InTransitIcon.vue'
import OutForDeliveryIcon from '@/views/Elements/Icons/TrackingStatus/OutForDeliveryIcon.vue'
import ErrorIcon from '@/views/Elements/Icons/TrackingStatus/ErrorIcon.vue'
import FailedAttemptIcon from '@/views/Elements/Icons/TrackingStatus/FailedAttemptIcon.vue'
import DeliveredIcon from '@/views/Elements/Icons/TrackingStatus/DeliveredIcon.vue'

export default {
  [INFO_RECEIVED]: {
    name: 'Info Received',
    color: GREEN,
    icon: InfoReceivedIcon,
  },
  [IN_TRANSIT]: {
    name: 'In Transit',
    color: GREEN,
    icon: InTransitIcon,
  },
  [OUT_FOR_DELIVERY]: {
    name: 'Out For Delivery',
    color: GREEN,
    icon: OutForDeliveryIcon,
  },
  [DELIVERED]: {
    name: 'Delivered',
    color: GREEN_SUCCESS,
    icon: DeliveredIcon,
  },
  [EXCEPTION]: {
    name: 'Error',
    color: RED,
    icon: ErrorIcon,
  },
  [FAILED_ATTEMPT]: {
    name: 'Failed Attempt',
    color: RED,
    icon: FailedAttemptIcon,
  },
}
